import * as Sentry from "@sentry/browser";
import { getEnvironment } from "@/helpers";

export function initSentry() {
  try {
    Sentry.init({
      dsn: getEnvironment("SENTRY_DASHBOARD_DSN"),
      release: getEnvironment("APP_VERSION"),
      environment: getEnvironment("APP_ENV"),
      allowUrls: [
        // Will only allow errors from urls that match *.brex.com/static/
        /[^\s]+\.brex\.com\/static/,
        // Will only allow errors from urls that match *.brexapps.com/static/
        /[^\s]+\.brexapps\.com\/static/,
        // Will only allow errors from urls that match *.brexhq.dev/
        /[^\s]+\.brexhq\.dev\//,
      ],
      attachStacktrace: true,
    });
  } catch {
    console.error("Failed to initialize Sentry");
  }
}

export const setSentryContext = (context: Sentry.User) => {
  try {
    Sentry.configureScope((scope) => {
      scope.setUser(context);
    });
  } catch {
    console.error("Failed to configure Sentry scope");
  }
};

export const setExtraSentryContext = (key: string, extra: any) => {
  try {
    Sentry.setExtra(key, extra);
  } catch {
    console.error("Failed to set extra Sentry context");
  }
};

export const captureSentryException = (error: any) =>
  Sentry.captureException(error);
